import { tossColor } from "@/containers/Toss/constants/color";
import { sessionStorage } from "@/utils/storage";
import { Box, Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { useOverlay } from "@toss/use-overlay";
import {
  VStack,
  HStack,
  OverlayModal,
  ColorIcon,
  colors,
  Button,
} from "goi_common";
import React from "react";

export default function useNoticeModal({
  isToss = false,
}: {
  isToss: boolean;
}) {
  const overlay = useOverlay();

  const openModal = () => {
    overlay.open(({ isOpen: isOpen, close: onClose }) => {
      return (
        <OverlayModal
          isOpen={isOpen}
          modalContentStyle={{
            minWidth: "360px",
            maxWidth: "360px",
            margin: "0 20px",
            minHeight: "none",
            borderRadius: "10px",
          }}
          overlayModalContent={
            <VStack padding="20px 28px" gap={20} width="100%">
              <HStack justifyContent="flex-end">
                <Box onClick={onClose}>
                  <ColorIcon
                    name="icon-x-mono"
                    color={colors.gray500}
                    size={24}
                  />
                </Box>
              </HStack>
              <Box
                className="gray900 subtitle_21_b"
                marginTop="-20px"
                lineHeight="28px"
              >
                고이 프라임 <br />
                25,000구좌 달성 기념
                <br />
                기간 한정 프로모션
              </Box>
              <Box className="gray900 body_16_m" wordBreak="keep-all">
                고이 프라임 100원 상조에 관심을 가져주시는 고객 여러분들께
                진심으로 감사드립니다.
                <br />
                <br />
                고이 프라임을 출시한 지 9개월만에 25,000구좌 달성을 기념하여{" "}
                <b
                  css={{ color: isToss ? tossColor["700"] : colors.orange650 }}
                >
                  4월 중순까지
                </b>{" "}
                상조 총액에서 10만원을 할인해드리는 프로모션을 진행합니다.
                <br />
              </Box>

              <Table
                className="caption_12_r"
                css={{
                  wordBreak: "keep-all",
                  ["th"]: {
                    fontSize: 12,
                    fontWeight: 600,
                    color: colors.gray500,
                    borderBottom: `1px solid ${colors.gray200}`,
                  },
                  ["td"]: {
                    border: `1px solid ${colors.gray200}`,
                  },
                  ["th, td"]: {
                    textAlign: "center",
                    verticalAlign: "middle",
                    padding: "7px 0",
                  },
                }}
              >
                <Tbody textAlign="center">
                  <Tr
                    background={isToss ? tossColor["700"] : colors.orange650}
                    className="white"
                    fontWeight={600}
                  >
                    <Td></Td>
                    <Td width="42%">25년도 금액</Td>
                    <Td width="42%">혜택가(4월 중)</Td>
                  </Tr>
                  <Tr>
                    <Td>가족장</Td>
                    <Td>279만원</Td>
                    <Td>269만원</Td>
                  </Tr>

                  <Tr>
                    <Td>일반장</Td>
                    <Td>379만원</Td>
                    <Td>369만원</Td>
                  </Tr>
                </Tbody>
              </Table>
              <Box
                className="gray600 body_14_r"
                wordBreak="keep-all"
                margin="-15px 0"
              >
                *이벤트는 당사 사정에 의해 4월 중 조기종료될 수 있습니다.
              </Box>

              <Box className="gray900 body_16_m" wordBreak="keep-all">
                <br />
                앞으로도 고객님들께 최상의 장례 서비스를 제공하기 위해
                노력하겠습니다.
                <br />
                <br />
                감사합니다.
              </Box>

              <Button
                className="white subtitle_14_sb"
                css={{
                  background: isToss ? tossColor["700"] : colors.orange650,
                  padding: "10px 20px",
                  borderRadius: "8px",
                  margin: "20px 0",
                }}
                onClick={onClose}
              >
                닫기
              </Button>
            </VStack>
          }
          onClose={() => {
            onClose();
          }}
        />
      );
    });

    sessionStorage.set("noticeModal", "true");
  };
  return { openModal };
}
